import { Component, OnInit, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import Swal from 'sweetalert2';
import { G_Installment_View_Model } from 'src/app/viewmodels/g-purchase/G_Installment_View_Model';
import { G_Bulk_Installment_View_Model } from 'src/app/viewmodels/g-purchase/G_Bulk_Installment_View_Model';
import { NgxSpinnerService } from 'ngx-spinner';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ExportExcel } from 'src/app/viewmodels/ExportExcel';
@Component({
  selector: 'app-direct-sale-list',
  templateUrl: './direct-sale-list.component.html',
  styleUrls: ['./direct-sale-list.component.scss']
})
export class DirectSaleListComponent implements OnInit {
  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  public modalRef: BsModalRef;
  p: number = 1;
  dataTable: any;
  @Input('dataTable') table;
  dtOption: any = {};
  public SortBy:string = "asc";
  myDate = new Date(Date.now());
  public component_Title = 'Direct Sale List';
  public itemVm: GVechileViewModel = new GVechileViewModel();
  public vm: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public vmI: G_Installment_View_Model = new G_Installment_View_Model();
  public bulkInstallment : G_Bulk_Installment_View_Model = new G_Bulk_Installment_View_Model();
  public vmListItems: any = [];
  public attachmentList: any = [];
  public listOfAttachments :any = [];
  public goodResponse: any = [];
  public vmDirectList :any = [];
  public thumbnail: any;
  public ComName: any;
  public Address: any;
  public contect: any;
  public customerDetail: any = null;
  public selectedcustomerDetail: any = null;
  public exportFormat: string = 'csv';
  startDate: any = null;
  endDate : any = null;
  public CustomerDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id',  // The field to use for the selected ID
    textField: 'name',  // The field to use for displaying the customer name
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
  };
 
  @Output() onSubmitted = new EventEmitter<any>();
  constructor(
    private datePipe: DatePipe,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getimage();
    this.spinner.show();
    this._uow.saleService.GetPagedDirectSale().subscribe((res: any) => {
      // this.vm = Object.assign({}, ...res);
    //   let response = Object.keys(res);
    //   let goodResponse = [];
    //   for ( let prop of response) {
    //     goodResponse.push(response[prop]);
    // }
    debugger
    this.vmDirectList = res.DtlList;
    this.vm = res.DtlList;
    this.listOfAttachments = this.vmDirectList
    .filter(record => record.AttachmentList && record.AttachmentList.length > 0) // Ensure AttachmentList exists and is not empty
    .flatMap(record => record.AttachmentList.map(attachment => ({ Id: record.Id, Name: attachment })));

    console.log(this.vm);
      // this._jq.showSpinner();
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      // this._jq.showSpinner();
      this.spinner.hide();
      Swal.fire(
        'Failure!',
        'Network Issue!',
        'error'
      )
    });
  }
  getimage()
  {
    this._uow.settingservice.GetByImage().subscribe((baseImage: any) =>{
      console.log(baseImage);
      if (baseImage != undefined &&  baseImage!==null) {
        if (baseImage.Attachment != undefined &&  baseImage.Attachment !==null) {

          this.thumbnail = "https://garagedesks.s3.eu-central-1.amazonaws.com/" + baseImage.Attachment;
          this.ComName = baseImage.ComName;
          this.Address = baseImage.Address;
          this.contect = baseImage.contect;
        }else{
          this.thumbnail = baseImage.ComName;
          this.ComName = baseImage.ComName;
          this.Address = baseImage.Address;
          this.contect = baseImage.contect;
        }
      }

    },(err)=>{
      console.log(err);
    })
  }
  // extrnal components
  onItemSubmitted(e) {
    console.log(e);
    if (e === true) {
      this.vm = new G_SaleMasterViewModel();
      this.getList();
    } else {
      this._jq.showSpinner();
    }
  }

  public searchTerm = '';
  getSearchForDirectSale(e) {
    if (this.searchTerm !== ' ') {
      // this._jq.showSpinner(true);
      this.spinner.show();
      this._uow.saleService.GetPagedSearchByDirectSale(this.searchTerm).subscribe((res: any) => {
        this.p = 1;
        this.vmDirectList = res.DtlList;
        console.log(this.vmDirectList);
        // this._jq.showSpinner();
        this.spinner.hide();
      },  (error) => {
        console.log(error);
        // this._jq.showSpinner();
        this.spinner.hide();
      });
    }else{
      this.ngOnInit();
    }
  }

  getList() {
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.saleService.GetList().subscribe((res: any) => {
      this.vmListItems = res;
      console.log(res);
      // this._jq.showSpinner();
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      // this._jq.showSpinner();
      this.spinner.hide();
    });
  }

  conformDelete() {
    this._uow.saleService.Delete(this.vm).subscribe((res: any) => {
      this.getList();
      this.modalRef.hide();
    }, (err) => {
      this._jq.showSpinner();
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }

  openAttachmentModal(template: TemplateRef<any>,b) {
    debugger
    this._uow.saleService.GetById(b).subscribe((res: any)=>{
      this.attachmentList = res.AttachmentList;
      console.log(this.attachmentList);
    });
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }

  openAttachmentHistoryModal(template: TemplateRef<any>,b) {
    debugger
    this.listOfAttachments = this.vmDirectList
    .filter(record => record.AttachmentList && record.AttachmentList.length > 0) // Ensure AttachmentList exists and is not empty
    .flatMap(record => record.AttachmentList.map(attachment => ({ Id: record.Id, Name: attachment })));
    this.listOfAttachments = this.listOfAttachments.filter(x=>x.Id === b);
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }

  openSModal(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-xl');
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openSModal(template);
    if (model !== undefined) {
      this.vm = model;
    } else {
      this.vm = new G_SaleMasterViewModel();
    }
  }

  openBulkPaymentModel(template: TemplateRef<any>, model?) {
    this.openSModal(template);
    if (model !== undefined) {
      this.bulkInstallment = model;
    }
  }

  openDeleteModel(template: TemplateRef<any>, model) {
    this.openModal(template);
    if (model !== undefined) {
      this.vm = model;
    }
  }

  openExportModel(template: TemplateRef<any>) {
    this.openModal(template);
    this.getCustomerName();
  }
  confirmDelete() {
    this.spinner.show();
    this._uow.saleService.Delete(this.vm).subscribe((res: any) => {
      this.ngOnInit();
      this.modalRef.hide();
      this.spinner.hide();
      Swal.fire(
        'Saved!',
        'Direct Sale Deletd!',
        'success'
      )
    }, (err) => {
      Swal.fire(
        'Saved!',
        'Direct Sale Not Deletd!',
        'error'
      );
      this.spinner.hide();
    });
  }

  getCustomerName() {
    debugger;
    this._uow.customerService.GetList().subscribe(
      (res: any) => {
        debugger;
        this.customerDetail = res.DtlList.map(x => {
          return {
            id: x.Id, // or the actual ID field
            name: x.Acc_Code + '-' + x.Acc_Titel // concatenated name and code
          };
        });
       
        // this.itemVm.RegistraionNumber = res;
        console.log(res);
        this._jq.showSpinner();
        // this.itemVm.RegistraionNumber = res.code;
      },
      (error) => {
        this._uow.logger.log(error);
        this._jq.showSpinner();
      }
    );
  }
  exportToExcel(){
debugger;
this.spinner.show();
    var obj = new ExportExcel();
    obj={
      Customer_Id: this.selectedcustomerDetail && this.selectedcustomerDetail.length > 0 
      ? this.selectedcustomerDetail[0].id 
      : null,  // Assign null if selectedcustomerDetail is null or empty
      StartDate: this.startDate,
      EndDate: this.endDate,
      Format: this.exportFormat
    }
this._uow.saleService.ExportDirectSale(obj).subscribe((res:any)=>{
debugger;

const blob = new Blob([res], { type: res.type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `SalesData_${new Date().toISOString().split('T')[0]}.${this.exportFormat.toLowerCase()}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        this.spinner.hide();
        this.modalRef.hide();
},
(error)=>{
  this._uow.logger.log(error);
  this.spinner.hide();
  Swal.fire(
    'Failure!',
    'Network Issue!',
    'error'
  )
});
  }
}
