import { Injectable } from '@angular/core';
import { IHttpService } from '../../common/IHttpService';
import { EndpointConfig } from '../../common/EndpointConfig';
import { HttpClient } from '@angular/common/http';
import { G_VehicleStatusViewModel } from 'src/app/viewmodels/g-settings/G_VehicleStatusViewModel';

@Injectable({
  providedIn: 'root'
})
export class GVehicleStatusService implements IHttpService<G_VehicleStatusViewModel> {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('GSettings');
  }

  GetList() {
    return this._client.get(this._ep.getEndpointMethod('GetG_VehicleStatusList'));
  }

  checkDublication(code) {
    return this._client.post(this._ep.getEndpointMethod('CheckStatusDublication'), { Code: code });
  }

  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_StatusById'), { Id: id });
  }
  Post(obj: G_VehicleStatusViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_VehicleStatus'), obj);
  }

  Put(obj: G_VehicleStatusViewModel) {
    return this._client.post(this._ep.getEndpointMethod('SaveG_VehicleStatus'), obj);
  }

  Delete(obj: G_VehicleStatusViewModel) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_VehicleStatus'), obj);
  }
}
