import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { G_VehicleStatusViewModel } from 'src/app/viewmodels/g-settings/G_VehicleStatusViewModel';

@Component({
  selector: 'app-vehicle-status',
  templateUrl: './vehicle-status.component.html',
  styleUrls: ['./vehicle-status.component.scss']
})
export class VehicleStatusComponent implements OnInit {

   modalRef: BsModalRef;
   public itemVm: G_VehicleStatusViewModel;
   public brands: any = [];
 
   constructor(
     private _jq: JqHelper,
     private _uow: UnitOfWorkService,
     private _modalService: BsModalService,
     private spinner: NgxSpinnerService
   ) { }
 
   ngOnInit() {
     this.getList();
   }
 
   openModal(template: TemplateRef<any>) {
     this.modalRef = this._uow.modalServiceEx.show(template);
   }
 
   ngAfterViewInit() {
     // this._jq.showSpinner(true);
   }
 
   getList() {
     // this._jq.showSpinner(true);
     this.spinner.show();
     this._uow.gVehicleStatusService.GetList().subscribe((res: any) => {
       this.brands = res;
       console.log(res);
       this.spinner.hide();
       // this._jq.showSpinner();
     }, (err) => {
       console.log(err);
       this.spinner.hide();
       // this._jq.showSpinner();
     });
   }
 
   openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
     this.openModal(template);
     if (model !== undefined) {
       this.itemVm = model;
     } else {
       this.itemVm = new G_VehicleStatusViewModel();
     }
   }
 
   openDeleteModel(template: TemplateRef<any>, model) {
     this.openModal(template);
     if (model !== undefined) {
       this.itemVm = model;
     }
   }
 
   conformDelete() {
     this.spinner.show();
     this._uow.gVehicleStatusService.Delete(this.itemVm).subscribe((res: any) => {
       this.getList();
       this.modalRef.hide();
       this.spinner.hide();
     }, (err) => {
       // this._jq.showSpinner();
       this.spinner.hide();
     });
   }
 
   // extrnal components
   onItemSubmitted(e) {
     console.log(e);
     if (e === true) {
       this.itemVm = new G_VehicleStatusViewModel();
       this.getList();
     }
   }
}
