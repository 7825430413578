import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { GVehicleNotifyViewModel } from 'src/app/viewmodels/g-vehicle/GVehicleNotifyViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-notify-form',
  templateUrl: './notify-form.component.html',
  styleUrls: ['./notify-form.component.scss']
})
export class NotifyFormComponent implements OnInit {
 @Input() public vm: GVehicleNotifyViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  private submitted = false;
  public vehicleDetail: any = [];
  public selectedvehicleDetail: any = [];
  modalRefLv1: BsModalRef;
  modalRef: BsModalRef;
  public statusList: any = [];
  public vehicleStatus: number = 0;
  files: File[] = [];
  public defaultDropdownSettings: IDropdownSettings = {
      singleSelection: true,
      idField: "Id",
      textField: "RegistraionNumber",
      enableCheckAll: false,
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  constructor(
      private _jq: JqHelper,
      private _uow: UnitOfWorkService,
      private _modalService: BsModalService,
      private spinner: NgxSpinnerService
    ) { }
  ngOnInit() {
    debugger;
    
    this.getRegNo();
    this.getStatusList();
    if(this.vm.Id != null && this.vm.Id != 0 && this.vm.Id != undefined){
      this.selectedvehicleDetail[0] = {
        Id: this.vm.FK_Vehicle.Id,
        RegistraionNumber: this.vm.FK_Vehicle.Name
      };
    }
  }
  onSelect(e) {
    

    this.files.push(...e.addedFiles);

    this.vm.file = this.files;
  }
  onRemove(e) {
    console.log(e);
    this.files.splice(this.files.indexOf(e), 1);
  }
  getRegNo() {
    this.spinner.show();
    this._uow.vehicleService.GetList().subscribe(
      (res: any) => {
        debugger
        this.vehicleDetail = res;
        // this.itemVm.RegistraionNumber = res;
        
        console.log(res);
        this.spinner.hide();
        // this.itemVm.RegistraionNumber = res.code;
      },
      (error) => {
        this._uow.logger.log(error);
        this.spinner.hide();
      }
    );
  }

   onSelectvehicleDetail(e) {
      debugger
      this._uow.logger.log(e);
     
      let item = this.vehicleDetail.find((x) => x.Id === e.Id);
      
      if (item !== undefined) {
        console.log(item);
        this.vm.CustomerName = item.Fk_G_Customer_Id.Name;
        this.vm.CustomerEmail = item.Fk_G_Customer_Id_Email.Name;
        this.vm.DriverName = item.DriverName;
        this.vm.DriverPhone = item.DriverPhone;
        this.vm.LastOdometer = item.Odometer;
        this.vm.CustomerBrand = item.Fk_G_Brand.Name;
        this.vehicleStatus = item.Fk_G_Status.Id;
      }
    }

     onDeSelectvehicleDetail(e) {
        if (this.selectedvehicleDetail.length == 0) {
          this.vm = new GVehicleNotifyViewModel();
          this.getRegNo();
        }
      }

      getStatusList() {
        // this._jq.showSpinner(true);
        this.spinner.show();
        this._uow.gVehicleStatusService.GetList().subscribe((res: any) => {
          debugger
          this.statusList = res;
          if(this.vm.Id != null && this.vm.Id != 0 && this.vm.Id != undefined){
            this.vehicleStatus = this.vm.FK_Status.Id;
          }
          console.log(res);
          this.spinner.hide();
          // this._jq.showSpinner();
        }, (err) => {
          console.log(err);
          this.spinner.hide();
          // this._jq.showSpinner();
        });
      }

       saveOrUpdate() {
          debugger
          if(this.vehicleStatus == 0 || this.vehicleStatus == null || this.vehicleStatus == undefined){
            Swal.fire(
              'Failure!',
              'Update the status from vehicle!',
              'error'
            )
            return;
          }
          this.spinner.show();
          this.vm.FK_Vehicle = {
            Id: this.selectedvehicleDetail[0].Id,
            Name: this.selectedvehicleDetail[0].RegistraionNumber
        };
        
        this.vm.FK_Status = {
            Id: this.vehicleStatus,
            Name: ""
        };
          this._uow.vehicleNotifyService
            .Post(this.vm)
            .toPromise().then((res: any) => {
              console.log(res);
              this.submitted = true;
              this.onSubmitted.emit(this.submitted);
              this.spinner.hide();
              Swal.fire(
                'Created!',
                'Vehicle Created/Updated!',
                'success'
              )
            })
            .catch((error) => {
              Swal.fire(
                'Failure!',
                'Vehicle Not Updated!',
                'error'
              )
              console.log(`Promise rejected with ${JSON.stringify(error)}`);
              this.onSubmitted.emit(this.submitted);
              console.log(error);
              this.spinner.hide();
            });
        }
}
