import { Injectable } from '@angular/core';

class RouteBuilder {
    public prePend(pieces: string[]) {
        let path = '';
        Array.from(pieces).forEach(x => {
            path += `${x}/`;
        });
        return path;
    }
}
class BaseRoute extends RouteBuilder {
    constructor(public Name, public Path?) {
        super();
        if (Path === undefined) {
            this.Path = `/${Name}`;
        }
    }

    /// <summary>
    /// for this function to work properly provide path consts from first level to last level parent
    /// if you mess that up don't complain go fuckyourself.
    /// </summary>
    public buildOwnPath(parents: string[]) {
        this.Path = `/${this.prePend(parents)}${this.Name}`;
        // console.log(this.path);
        return this;
    }

}

export class DashboardRoutes extends RouteBuilder {
    public DashRoute = new BaseRoute('dashboard');
    public Item = new BaseRoute(`item`);

}

export class AuthenticationRoutes extends RouteBuilder {
    public Login = new BaseRoute('login');
}

export class CustomerRoutes extends RouteBuilder {
    public List = new BaseRoute('customer');
}
export class SupplierRoutes extends RouteBuilder {
    public List = new BaseRoute('supplier');
}
export class PurchaseRoutes extends RouteBuilder {
    public PurchaseRequisition = new BaseRoute('purchase-requsition');
    public PurchaseOrder = new BaseRoute('purchase-order');
    public Purchase = new BaseRoute('purchase');
    public purchasepdf = new BaseRoute ('purchase-pdf-invoice');
}
export class InventoryRoutes extends RouteBuilder{
    public Stocks = new BaseRoute('stock-list');
    public InventoryFlow = new BaseRoute('inventory-flow-report');
}
export class SaleRoutes extends RouteBuilder {
  public Sale = new BaseRoute('sale');
  public repair = new BaseRoute ('repair-order');
  public prepareinvoice = new BaseRoute ('prepare-invoice');
  public repairorderlist = new BaseRoute ('repair-order-list');
  public allsaleslist = new BaseRoute ('all-sales-list');
  public wiprepairorders = new BaseRoute ('wip-repair-orders');
  public readylist = new BaseRoute ('ready-list');
  public directSale = new BaseRoute ('direct-sale');
  public directsalelist = new BaseRoute ('direct-sale-list');
  public paymentstatus = new BaseRoute ('payment-status');
  public bulkPayment = new BaseRoute ('bulk-payments');
  public bulkPaymentList = new BaseRoute ('bulk-payments-list');
  public salecompleted = new BaseRoute ('sale-completed');
  public quotation = new BaseRoute ('quotation');
}
export class VehicleRoutes extends RouteBuilder {
    public List = new BaseRoute('vehicle');
    public VehicleNotify = new BaseRoute('vehicle-notify');
}

export class GarageSettingRoutes extends RouteBuilder {
    public GarageRoute = new BaseRoute('garage-settings');
    public FuleType = new BaseRoute(`fule-type`).buildOwnPath([this.GarageRoute.Name]);
    public Brand = new BaseRoute(`brand`).buildOwnPath([this.GarageRoute.Name]);
    public Item = new BaseRoute(`item`).buildOwnPath([this.GarageRoute.Name]);
    public ItemStockReport = new BaseRoute(`stock-report`).buildOwnPath([this.GarageRoute.Name]);
    public WareHouseItemStockReport = new BaseRoute(`warehouse-stock-report`).buildOwnPath([this.GarageRoute.Name]);
    public AggingReport = new BaseRoute(`agging-report`).buildOwnPath([this.GarageRoute.Name]);
    public SalesReport = new BaseRoute(`sales-report`).buildOwnPath([this.GarageRoute.Name]);
    public StockAdjustment = new BaseRoute(`stock-adjustment-list`).buildOwnPath([this.GarageRoute.Name]);
    public ItemType = new BaseRoute(`item-type`).buildOwnPath([this.GarageRoute.Name]);
    public Manufacturer = new BaseRoute(`manufacturer`).buildOwnPath([this.GarageRoute.Name]);
    public VehicleModel = new BaseRoute(`model`).buildOwnPath([this.GarageRoute.Name]);
    public SsCategory = new BaseRoute(`ss-category`).buildOwnPath([this.GarageRoute.Name]);
    public StatusType = new BaseRoute(`status-type`).buildOwnPath([this.GarageRoute.Name]);
    public Tags = new BaseRoute(`tags`).buildOwnPath([this.GarageRoute.Name]);
    public TagsType = new BaseRoute(`tags-type`).buildOwnPath([this.GarageRoute.Name]);
    public PackageType = new BaseRoute(`package-type`).buildOwnPath([this.GarageRoute.Name]);
    public Package = new BaseRoute(`package`).buildOwnPath([this.GarageRoute.Name]);
    public Colors = new BaseRoute(`colors`).buildOwnPath([this.GarageRoute.Name]);
    public ComplainsAndSuggestions = new BaseRoute(`complains-and-suggestions`).buildOwnPath([this.GarageRoute.Name]);
    public Units = new BaseRoute(`units`).buildOwnPath([this.GarageRoute.Name]);
    public Countries = new BaseRoute(`countries`).buildOwnPath([this.GarageRoute.Name]);
    public Warehouse = new BaseRoute(`warehouse`).buildOwnPath([this.GarageRoute.Name]);
    public VMHReport = new BaseRoute(`vmh-report`).buildOwnPath([this.GarageRoute.Name]);
    public VehicleStatus = new BaseRoute(`vehicle-status`).buildOwnPath([this.GarageRoute.Name]);
}

// main exporter
@Injectable()
export class RouteConsts {
    public DashBoard: DashboardRoutes = new DashboardRoutes();
    public Authentication: AuthenticationRoutes = new AuthenticationRoutes();
    public GarageSettings: GarageSettingRoutes = new GarageSettingRoutes();
    public Customer: CustomerRoutes = new CustomerRoutes();
    public Supplier: SupplierRoutes = new SupplierRoutes();
    public Vehicle: VehicleRoutes = new VehicleRoutes();
    public Purchase: PurchaseRoutes = new PurchaseRoutes();
    public Sale: SaleRoutes = new SaleRoutes();
    public repair: SaleRoutes = new SaleRoutes();
    public inventory:InventoryRoutes = new InventoryRoutes();


}
