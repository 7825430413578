import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { G_ColoursViewModel } from 'src/app/viewmodels/g-settings/G_ColoursViewModel';
import { G_VehicleStatusViewModel } from 'src/app/viewmodels/g-settings/G_VehicleStatusViewModel';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-status-form',
  templateUrl: './status-form.component.html',
  styleUrls: ['./status-form.component.scss']
})
export class StatusFormComponent implements OnInit {
@Input() public vm: G_VehicleStatusViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  private submitted = false;
  public IsDublicate: boolean = false;
  modalRef: BsModalRef;
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
  ) { }


  ngOnInit() {
     if (this.vm === undefined) {
       this.vm = new G_VehicleStatusViewModel();
     }
     if (this.modal_title === undefined) {
       this.modal_title = 'Vehicle Status';
     }
   }
 
   saveOrUpdate() {
     this._jq.showSpinner(true);
     this._uow.gVehicleStatusService
       .Post(this.vm)
       .toPromise().then((res: any) => {
         console.log(res);
         this.submitted = true;
         this.bsmRef.hide();
         this.onSubmitted.emit(this.submitted);
         Swal.fire(
           'Created!',
           'Color Created/Updated!',
           'success'
         )
       })
       .catch((error) => {
         Swal.fire(
           'Failure!',
           'Color Not Updated!',
           'error'
         )
         console.log(`Promise rejected with ${JSON.stringify(error)}`);
         this.onSubmitted.emit(this.submitted);
       });
   }
 
   checkStatusDublication(tagName){
     debugger
     this._jq.showSpinner(true);
     this._uow.gVehicleStatusService.checkDublication(tagName).toPromise().then((res : any)=>{
       if (res === true) {
         this.IsDublicate = false;
       }else{
         this.IsDublicate = true;
       }
       this._jq.showSpinner(false);
     }).catch((error) => {
       console.log(`Promise rejected with ${JSON.stringify(error)}`);
       this.onSubmitted.emit(this.submitted);
       this._jq.showSpinner(false);
     });
   }
 
   openTagTypeModel(template: TemplateRef<any>) {
     this.openModal(template);
   }
 
   openModal(template: TemplateRef<any>, lvl: number = 1) {
     if (lvl == 1) {
       this.modalRef = this._modalService.show(template);
     }
   }
 
   onStatusSubmitted(e) {
     if (e == true) {
       this.modalRef.hide();
       this.ngOnInit();
     }
   }
}
