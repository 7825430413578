import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConsts } from 'src/app/common/app-consts';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { ExportExcel } from 'src/app/viewmodels/ExportExcel';
import { GVehicleNotifyViewModel } from 'src/app/viewmodels/g-vehicle/GVehicleNotifyViewModel';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vehicle-notify',
  templateUrl: './vehicle-notify.component.html',
  styleUrls: ['./vehicle-notify.component.scss']
})
export class VehicleNotifyComponent implements OnInit {
 modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  modalRefLv3: BsModalRef;
  public component_Title = 'Vechicle Notify';
  public vmListItems: any = [];
  public statusListItems: any = [];
  public SortBy:string = "asc";
  p: number = 1;
  public itemVm: GVehicleNotifyViewModel = new GVehicleNotifyViewModel();
  dataTable: any;
  dtOption: any = {};
  public exportFormat: string = 'csv';
  public vehicleStatus: number = 0;
  startDate: any = null;
  endDate : any = null;
  public attachmentList: any = [];
  constructor(
      private _jq: JqHelper,
      private _uow: UnitOfWorkService,
      private _modalService: BsModalService,
      private spinner: NgxSpinnerService
    ) { }

   ngOnInit() {
      this.getList();
      this.dtOption = {
        "paging":   false,
        "searching": false,
        "info": false,
    };
    $(() =>{
      $('table.display').DataTable(this.dtOption);
    })
    }
  
    openModal(template: TemplateRef<any>, lvl: number) {
      if (lvl == 1) {
        this.modalRefLv1 = this._modalService.show(template);
        this.modalRefLv1.setClass('modal-lg');
      }
      if (lvl == 2) {
        this.modalRefLv2 = this._modalService.show(template);
      }
      if (lvl == 3) {
        this.modalRefLv3 = this._modalService.show(template);
      }
    }
  
    ngAfterViewInit() {
      // this._jq.showSpinner();
    }
  
    getList() {
      // this._jq.showSpinner(true);
      debugger
      this.spinner.show();
      this._uow.vehicleNotifyService.GetPagedResult().subscribe((res: any) => {
        this.vmListItems = res;
        console.log(res);
        debugger
        // this._jq.showSpinner();
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
        Swal.fire(
          'Failure!',
          'Network Issue!',
          'error'
        )
      });
    }
  
    getSortIndexList(sIndex) {
      // this._jq.showSpinner(true);
      this.spinner.show();
      this.SortBy = this.SortBy == "asc" ? "desc" : "asc";
      this._uow.vehicleNotifyService.GetPagedResultBySort(0, 50, AppConsts.VEHICLE, sIndex, this.SortBy).subscribe((res: any) => {
        debugger
        this._jq.reinitDataTable(res);
        this.vmListItems = res;;
        console.log(res);
        this.spinner.hide();
        // this._jq.showSpinner();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });
    }
  
    public searchTerm = '';
    getBySearchTerm(e) {
      if (this.searchTerm !== ' ') {
        // this._jq.showSpinner(true);
        this.spinner.show();
        this._uow.vehicleNotifyService.GetPagedBySearch(this.searchTerm).subscribe((res: any) => {
          this.p = 1;
        this.vmListItems = res;
          // this._jq.showSpinner();
        this.spinner.hide();
        }, (error) => {
          console.log(error);
          // this._jq.showSpinner();
        this.spinner.hide();
  
        });
      }
    }
  
    openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
      this.openModal(template, 1);
      if (model !== undefined) {
        this.itemVm = model;
      } else {
        this.itemVm = new GVehicleNotifyViewModel();
      }
    }
    openDeleteModel(template: TemplateRef<any>, model) {
      this.openModal(template, 1);
      if (model !== undefined) {
        this.itemVm = model;
      }
    }
  
    conformDelete() {
      this.spinner.show();
      this._uow.vehicleNotifyService.Delete(this.itemVm).subscribe((res: any) => {
        this.getList();
        this.modalRefLv1.hide();
        this.spinner.hide();
      }, (err) => {
        // this._jq.showSpinner();
        this.spinner.hide();
      });
    }
  
    // external components
    onItemSubmitted(e) {
      debugger
      if (e == true) {
        this.modalRefLv1.hide();
        this.getList();
        this._jq.showSpinner(false);
      }
    }
  
  
    openExportModel(template: TemplateRef<any>) {
      this.openModal(template, 1);
    }
  
   
    exportToExcel(){
      debugger;
      this.spinner.show();
          var obj = new ExportExcel();
          obj={
            StartDate: this.startDate,
            EndDate: this.endDate,
            Format: this.exportFormat
          }
      this._uow.vehicleNotifyService.ExportVehicle(obj).subscribe((res:any)=>{
      debugger;
      
      const blob = new Blob([res], { type: res.type });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = `VehicleNotify_${new Date().toISOString().split('T')[0]}.${this.exportFormat.toLowerCase()}`;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
      
              this.spinner.hide();
              this.modalRefLv1.hide();
      },
      (error)=>{
        this._uow.logger.log(error);
        this.spinner.hide();
        Swal.fire(
          'Failure!',
          'Network Issue!',
          'error'
        )
      });
        }


        openAttachmentModal(template: TemplateRef<any>, b: any) {
          debugger;
          // Filter and map attachments where Id matches b
          this.attachmentList = this.vmListItems.reduce((acc, item) => {
            // Filter attachments with matching Id
            const attachments = item.ListOfAttachment
              .filter(att => att.FK_VehicleNotify_Id === b) // Filter condition
              .map(att => att.Attachment); // Map to extract Attachment
            return acc.concat(attachments);
          }, []);
          
          this.modalRefLv2 = this._uow.modalServiceEx.show(template);
          this.modalRefLv2.setClass('modal-lg');
        }
}
