import { ItemViewModel } from '../ItemViewModel';

export class GVehicleNotifyViewModel {
  constructor(){
    this.FK_Status = new ItemViewModel();
    this.FK_Vehicle = new ItemViewModel();
  }
    public Id: number;
    public LastOdometer: number;
    public CurrentOdometer: number;
    public CustomerName: string;
    public CustomerEmail: string;
    public CustomerBrand: string;
    public DriverName: string;
    public DriverPhone: string;
    public EmailCount: number;
    public FK_Status: ItemViewModel;
    public FK_Vehicle: ItemViewModel;
    public ListOfAttachment: GVehicleNotifyAttachmentViewModel[];
    public file: any = [];
}

export class GVehicleNotifyAttachmentViewModel{
  public Id: number;
  public Name: string;
  public Attachment: string;
  public FK_VehicleNotify_Id: number;
}

